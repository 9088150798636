import React from 'react';
import { connect } from 'react-redux';
import { Button, DataTable, SearchInput, Pager } from 'tyb';
import queryString from 'query-string';
import { TablePopConfirm } from 'ucode-components';
import { getpromotionUploadList } from '../../../../redux/modules/tagbatchConfigs/tagbatchConfigs';
// import { tagbatch as gettagbatch, downloadPackage } from '../../../../redux/modules/tagbatch/tagbatch';

// import { downloadMemberInfo as gettagbatch } from '../../../../redux/modules/memberManage/memberManage';

import './index.scss';

@connect(
    state => ({
        promotionUploadList: state.tagbatchConfigs.promotionUploadList,
        tagbatch: state.memberManage.downloadMemberInfoback,

        downloadPackageUrl: state.tagbatch.downloadPackageUrl,
        downloadError: state.tagbatch.downloadError,
    }),
    {
        getpromotionUploadList: getpromotionUploadList.REQUEST,
        // gettagbatch: gettagbatch.REQUEST,
        // downloadPackage: downloadPackage.REQUEST,
    }
)
class ExtractionCodeFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selections: [],
            keywords: '',
            page: 0,
            perPageCount: 20,
            showContent: [],
            totalElements: 0,
        };
        this.columns = [
            // {
            //     type: 'selection',
            //     width: 40,
            //     key: '_selection',
            //     dataIndex: 'id'
            // },
            {
                key: 'batchNo',
                dataIndex: 'batchNo',
                title: '生产批次'
            },
            {
                key: 'tb',
                dataIndex: 'tb',
                title: '关联码规格'
            },
            {
                key: 'promotionName',
                dataIndex: 'promotionName',
                title: '活动名称'
            },
            // {
            //     key: 'downloadLinks',
            //     dataIndex: 'downloadLinks',
            //     title: '操作',
            //     render: (val, record) =>
            //         <div>
            //             <a href="javascript:;" onClick={() => { this.handleVisible(record) }}>下载</a>
            //             {/* <a href="javascript:;" onClick={() => { this.props.history.push(`/extractionCodeHistory?batchKey=${record.batchKey}&batchName=${record.name}`) }}>历史</a> */}
            //             {this.state.visible == `${record.id}_visible` && <TablePopConfirm
            //                 strongText={'确认下载？'}
            //                 text={'该批次已下载过，确认再次下载'}
            //                 // styles={{ right: 0, }}
            //                 button={['确定', '取消']}
            //                 onOk={() => { this.handleDownload(record) }}
            //                 onCancel={() => { this.setState({ visible: '' }) }}
            //             />}
            //         </div>
            // },
        ]
    }


    componentDidMount() {

        this.props.getpromotionUploadList({

            callback: (data) => {
                let newdata = JSON.parse(JSON.stringify(data));
                this.setState({ showContent: JSON.parse(JSON.stringify(newdata)).content.splice(0, this.state.perPageCount), totalElements: newdata.content.length })
            }
        });

    }

    componentDidUpdate(preProps) {
        // if (this.props.tagbatch && this.props.tagbatch !== preProps.tagbatch) {
        //     const { downloadUrl } = this.props.tagbatch;
        //     if (downloadUrl) {
        //         window.location.href = downloadUrl;
        //         setTimeout(() => {
        //             this.props.extractionCode(this.EXTRACTIONCODE);
        //         }, 200);
        //     }
        // }

        // if (this.props.downloadPackageUrl && this.props.downloadPackageUrl !== preProps.downloadPackageUrl) {
        //     const { downloadUrl } = this.props.downloadPackageUrl;
        //     if (downloadUrl) {
        //         window.location.href = downloadUrl;
        //         setTimeout(() => {
        //             this.props.extractionCode(this.EXTRACTIONCODE);
        //         }, 200);
        //     }
        // }
    }

    // handleVisible(data) {
    //     if (data.downloadCount) {

    //         this.setState({ visible: `${data.id}_visible` })
    //     } else {
    //         this.handleDownload(data);
    //     }
    // }

    // handleSelectionChange = (key, record, checked) => {
    //     const { selections } = this.state;
    //     if (checked) {
    //         if (selections.indexOf(key) < 0) {
    //             this.setState({
    //                 selections: [...selections, key]
    //             })
    //         }
    //     } else {
    //         this.setState({
    //             selections: selections.filter(selected => selected !== key)
    //         })
    //     }
    // }

    // handleSelectionAllChange = (checked) => {
    //     this.setState({
    //         selections: checked ? this.props.promotionUploadList.map(d => d.id) : []
    //     })
    // }

    changeSearchBox = (searchText) => {
        this.setState({
            keywords: searchText
        })
    }
    search = () => {
        let newdata = JSON.parse(JSON.stringify(this.props.promotionUploadList)).content.filter(key => key.batchNo.indexOf(this.state.keywords) >= 0 || key.tb.toString().indexOf(this.state.keywords) >= 0);
        this.setState({ showContent: JSON.parse(JSON.stringify(newdata)).splice(0, this.state.perPageCount),page: 0, totalElements: newdata.length });
    }
    // 切换页数
    onPageChange = (val) => {
        let newdata = JSON.parse(JSON.stringify(this.props.promotionUploadList)).content.filter(key => key.batchNo.indexOf(this.state.keywords) >= 0 || key.tb.toString().indexOf(this.state.keywords) >= 0);
        this.setState({ page: val, showContent: JSON.parse(JSON.stringify(newdata)).splice(val * this.state.perPageCount, this.state.perPageCount), totalElements: newdata.length })
        // this.props.getMinProgramTemplate(this.params);
    }

    // 切换每页数量
    onPerPageChange = (val) => {
        let newdata = JSON.parse(JSON.stringify(this.props.promotionUploadList)).content.filter(key => key.batchNo.indexOf(this.state.keywords) >= 0 || key.tb.toString().indexOf(this.state.keywords) >= 0);
        this.setState({ showContent: JSON.parse(JSON.stringify(newdata)).splice(0, val), perPageCount: val, page: 0, totalElements: newdata.length })
        // this.props.getMinProgramTemplate(this.params);
    }

    // handleBatchDownload = () => {
    //     const { selections } = this.state;
    //     if (!selections.length) {
    //         notification.warning('请选择批次');
    //         return;
    //     }

    //     const downloadBatchs = [];
    //     this.props.extractionList.forEach(val => {
    //         if (selections.includes(val.id)) {
    //             downloadBatchs.push({ key: val.batchKey, extractionCode: val.extractedCode });
    //         }
    //     });

    //     this.props.downloadPackage(downloadBatchs);
    // }


    // handleBatchDownloadHistory = () => {
    //     const index = this.EXTRACTIONCODE.lastIndexOf('\/');
    //     const key = this.EXTRACTIONCODE.substring(index + 1, this.EXTRACTIONCODE.length).split('?')[0];
    //     this.props.history.push(`/extractionCodeHistory?key=${key}`);
    // }

    // handleDownload = (data) => {
    //     // this.props.gettagbatch({ key: data.id, extractionCode: data.extractedCode });
    //     window.location.href = data.downloadLink+data.extractedCode;
    //     setTimeout(() => {
    //         this.props.extractionCode(this.EXTRACTIONCODE);
    //     }, 200);



    // this.fileName = data.fileName;
    // // this.props.downloadExtractionCode(`${data.downloadLinks}?extractionCode=${data.extractedCode}`);
    // window.location.href = `${data.downloadLinks}?extractionCode=${data.extractedCode}`;
    // }

    render() {
        const { promotionUploadList, downloadError } = this.props;
        return (

            <div className="extraction-file">
                <div className="navbar "><h3>批次信息</h3></div>
                <section className="app-main withtab">
                    <div className="main">
                        {downloadError && <div className="hint">{downloadError}</div>}
                        {/* <div className="bt-group">
                            <Button onClick={this.handleBatchDownload}>批量下载</Button>
                            <Button className="tc-15-btn weak" onClick={this.handleBatchDownloadHistory}>批量下载历史</Button>
                        </div> */}
                        <div className="container">
                            <div className="search-input-block">
                                <SearchInput
                                    onChange={this.changeSearchBox}
                                    onSearch={this.search}
                                    value={this.state.keywords}
                                    placeholder="输入生产批次/码规格查询"
                                />
                            </div>
                            <DataTable
                                columns={this.columns}
                                data={this.state.showContent}
                                rowKey="id"
                            // selection={{
                            //     value: this.state.selections,
                            //     onChange: this.handleSelectionChange,
                            //     onChangeAll: this.handleSelectionAllChange,
                            // }}
                            />
                            {this.state.totalElements > 0 && <Pager
                                total={this.state.totalElements}
                                current={this.state.page}
                                perPageCountList={[20, 50, 100]}
                                perPageCount={this.state.perPageCount}
                                onPageChange={this.onPageChange}
                                onPerPageChange={this.onPerPageChange}
                            />}
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}


export default ExtractionCodeFile;