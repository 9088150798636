import React from 'react';
import { connect } from 'react-redux';
import { Button, DataTable, notification } from 'tyb';
import queryString from 'query-string';
import { TablePopConfirm } from 'ucode-components';
import { extractionCode } from '../../../../redux/modules/tagbatchConfigs/tagbatchConfigs';
// import { tagbatch as gettagbatch, downloadPackage } from '../../../../redux/modules/tagbatch/tagbatch';

// import { downloadMemberInfo as gettagbatch } from '../../../../redux/modules/memberManage/memberManage';

import './index.scss';

@connect(
    state => ({
        extractionList: state.tagbatchConfigs.extractionList,
        tagbatch: state.memberManage.downloadMemberInfoback,

        downloadPackageUrl: state.tagbatch.downloadPackageUrl,
        downloadError: state.tagbatch.downloadError,
    }),
    {
        extractionCode: extractionCode.REQUEST,
        // gettagbatch: gettagbatch.REQUEST,
        // downloadPackage: downloadPackage.REQUEST,
    }
)
class ExtractionCodeFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selections: [],
        };
        this.columns = [
            // {
            //     type: 'selection',
            //     width: 40,
            //     key: '_selection',
            //     dataIndex: 'id'
            // },
            {
                key: 'id',
                dataIndex: 'id',
                title: 'ID'
            },
            {
                key: 'fileName',
                dataIndex: 'fileName',
                title: '文件名称'
            },
            // {
            //     key: 'tagCount',
            //     dataIndex: 'tagCount',
            //     title: '码量'
            // },
            {
                key: 'downloadLinks',
                dataIndex: 'downloadLinks',
                title: '操作',
                render: (val, record) =>
                    <div>
                        <a href="javascript:;" onClick={() => { this.handleVisible(record) }}>下载</a>
                        {/* <a href="javascript:;" onClick={() => { this.props.history.push(`/extractionCodeHistory?batchKey=${record.batchKey}&batchName=${record.name}`) }}>历史</a> */}
                        {this.state.visible == `${record.id}_visible` && <TablePopConfirm
                            strongText={'确认下载？'}
                            text={'该批次已下载过，确认再次下载'}
                            // styles={{ right: 0, }}
                            button={['确定', '取消']}
                            onOk={() => { this.handleDownload(record) }}
                            onCancel={() => { this.setState({ visible: '' }) }}
                        />}
                    </div>
            },
        ]
    }


    componentDidMount() {
        const query = queryString.parse(window.location.search);
        const extractionCode = query.extractionCode;
        if (extractionCode) {
            let EXTRACTIONCODE = window.localStorage.getItem('EXTRACTIONCODE');
            try {
                EXTRACTIONCODE = JSON.parse(EXTRACTIONCODE);
            } catch (error) {

            }

            if (EXTRACTIONCODE && EXTRACTIONCODE[extractionCode]) {
                this.EXTRACTIONCODE = EXTRACTIONCODE[extractionCode];
                this.props.extractionCode(EXTRACTIONCODE[extractionCode]);
            } else {
                window.localStorage.setItem('EXTRACTIONCODE', JSON.stringify({}));
            }
        }
    }

    componentDidUpdate(preProps) {
        // if (this.props.tagbatch && this.props.tagbatch !== preProps.tagbatch) {
        //     const { downloadUrl } = this.props.tagbatch;
        //     if (downloadUrl) {
        //         window.location.href = downloadUrl;
        //         setTimeout(() => {
        //             this.props.extractionCode(this.EXTRACTIONCODE);
        //         }, 200);
        //     }
        // }

        // if (this.props.downloadPackageUrl && this.props.downloadPackageUrl !== preProps.downloadPackageUrl) {
        //     const { downloadUrl } = this.props.downloadPackageUrl;
        //     if (downloadUrl) {
        //         window.location.href = downloadUrl;
        //         setTimeout(() => {
        //             this.props.extractionCode(this.EXTRACTIONCODE);
        //         }, 200);
        //     }
        // }
    }

    handleVisible(data) {
        if (data.downloadCount) {

            this.setState({ visible: `${data.id}_visible` })
        } else {
            this.handleDownload(data);
        }
    }

    handleSelectionChange = (key, record, checked) => {
        const { selections } = this.state;
        if (checked) {
            if (selections.indexOf(key) < 0) {
                this.setState({
                    selections: [...selections, key]
                })
            }
        } else {
            this.setState({
                selections: selections.filter(selected => selected !== key)
            })
        }
    }

    handleSelectionAllChange = (checked) => {
        this.setState({
            selections: checked ? this.props.extractionList.map(d => d.id) : []
        })
    }

    handleBatchDownload = () => {
        const { selections } = this.state;
        if (!selections.length) {
            notification.warning('请选择批次');
            return;
        }

        const downloadBatchs = [];
        this.props.extractionList.forEach(val => {
            if (selections.includes(val.id)) {
                downloadBatchs.push({ key: val.batchKey, extractionCode: val.extractedCode });
            }
        });

        this.props.downloadPackage(downloadBatchs);
    }


    handleBatchDownloadHistory = () => {
        const index = this.EXTRACTIONCODE.lastIndexOf('\/');
        const key = this.EXTRACTIONCODE.substring(index + 1, this.EXTRACTIONCODE.length).split('?')[0];
        this.props.history.push(`/extractionCodeHistory?key=${key}`);
    }

    handleDownload = (data) => {
        // this.props.gettagbatch({ key: data.id, extractionCode: data.extractedCode });
        window.location.href = data.downloadLink+data.extractedCode;
        setTimeout(() => {
            this.props.extractionCode(this.EXTRACTIONCODE);
        }, 200);



        // this.fileName = data.fileName;
        // // this.props.downloadExtractionCode(`${data.downloadLinks}?extractionCode=${data.extractedCode}`);
        // window.location.href = `${data.downloadLinks}?extractionCode=${data.extractedCode}`;
    }

    render() {
        const { extractionList, downloadError } = this.props;
        return (
            <div className="extraction-file">
                <div className="navbar "><h3>会员信息下载</h3></div>
                <section className="app-main withtab">
                    <div className="main">
                        {downloadError && <div className="hint">{downloadError}</div>}
                        {/* <div className="bt-group">
                            <Button onClick={this.handleBatchDownload}>批量下载</Button>
                            <Button className="tc-15-btn weak" onClick={this.handleBatchDownloadHistory}>批量下载历史</Button>
                        </div> */}
                        <div className="container">

                            <DataTable
                                columns={this.columns}
                                data={extractionList}
                                rowKey="id"
                                selection={{
                                    value: this.state.selections,
                                    onChange: this.handleSelectionChange,
                                    onChangeAll: this.handleSelectionAllChange,
                                }}
                            />
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}


export default ExtractionCodeFile;